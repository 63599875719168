import React, { Suspense, useEffect } from 'react';
import {Route,Switch,BrowserRouter,Redirect,Link, useParams, useHistory, useLocation} from 'react-router-dom';
import {IonRouterOutlet, IonCard, IonCardContent, IonRoute, IonSkeletonText} from '@ionic/react';
import {useStoreContext} from './store/';


const Products  = React.lazy(()=>import('./pages/Products'));
const Product  = React.lazy(()=>import('./pages/Product'));
// Admin components
const Categories  = React.lazy(()=>import('./pages/Admin/Categories/'));
const CategoriesForm  = React.lazy(()=>import('./pages/Admin/Categories/Form'));
const Registration  = React.lazy(()=>import('./pages/Registration'));
const Login  = React.lazy(()=>import('./pages/Login'));
const Search  = React.lazy(()=>import('./pages/Search'));
const Cart  = React.lazy(()=>import('./pages/Cart'));
const CheckOut  = React.lazy(()=>import('./pages/CheckOut'));
const MyOrders  = React.lazy(()=>import('./pages/MyOrders'));
const Registrations  = React.lazy(()=>import('./pages/Admin/Registrations'));
const AdminDashboard  = React.lazy(()=>import('./pages/Admin/Index'));
const Ticker  = React.lazy(()=>import('./pages/Admin/Ticker/Index'));
const Banners  = React.lazy(()=>import('./pages/Admin/Banner'));
const CartTracker  = React.lazy(()=>import('./pages/Admin/CartTracker'));
const BannerForm  = React.lazy(()=>import('./pages/Admin/Banner/Form'));
const Orders  = React.lazy(()=>import('./pages/Admin/Orders'));
const CategoryForm  = React.lazy(()=>import('./pages/Admin/Categories/Form'));
const ProductsAdmin   = React.lazy(()=>import('./pages/Admin/Products/'));
const ProductForm  = React.lazy(()=>import('./pages/Admin/Products/Form'));
const AboutUs  = React.lazy(()=>import('./pages/AboutUs'));
const ContactUs  = React.lazy(()=>import('./pages/ContactUs'));
const WebMenu  = React.lazy(()=>import('./pages/Admin/WebMenu/Index'));
const AdminUsers  = React.lazy(()=>import('./pages/Admin/AdminUsers'));
const AdminUserForm  = React.lazy(()=>import('./pages/Admin/AdminUsers/Form'));
const ProductTypes  = React.lazy(()=>import('./pages/Admin/ProductTypes'));
const ProductTypeForm  = React.lazy(()=>import('./pages/Admin/ProductTypes/Form'));
const ProductSubType  = React.lazy(()=>import('./pages/Admin/ProductSubTypes'));
const ProductSubTypeForm  = React.lazy(()=>import('./pages/Admin/ProductSubTypes/Form'));
const QuickOrder  = React.lazy(()=>import('./pages/QuickOrder'));
const MyAccount  = React.lazy(()=>import('./pages/MyAccount'));
const EditAccount  = React.lazy(()=>import('./pages/EditAccount'));
const EditAddressBook  = React.lazy(()=>import('./pages/EditAddressBook'));
const DContent  = React.lazy(()=>import('./pages/Admin/DContent'));
const RelatedProducts  = React.lazy(()=>import('./pages/RelatedProducts'));
const AccessDenied  = React.lazy(()=>import('./pages/AccessDenied'));
const CatMenu  = React.lazy(()=>import('./pages/Admin/CatMenu/Index'));
const ProductClass  = React.lazy(()=>import('./pages/Admin/ProductClass'));
const ProductClassForm  = React.lazy(()=>import('./pages/Admin/ProductClass/Form'));
//const Test  = React.lazy(()=>import('./pages/Test'));
const ResetPassword  = React.lazy(()=>import('./pages/ResetPassword'));
const TradeShows  = React.lazy(()=>import('./pages/Admin/TradeShows'));
const TradeShowsForm  = React.lazy(()=>import('./pages/Admin/TradeShows/Form'));
const SalesPersons = React.lazy(()=>import('./pages/Admin/SalesPersons'));
const SalesPersonForm = React.lazy(()=>import('./pages/Admin/SalesPersons/Form'));
const Schedular = React.lazy(()=>import('./pages/Admin/Schedular'));
const Theme = React.lazy(()=>import('./pages/Admin/Theme/Index'));

const ProductsAZ = React.lazy(()=>import('./pages/ProductsAZ'));
const Dashboard = React.lazy(() => import('./pages/Dashboard'));


const ShungiteHome = React.lazy(() => import('./pages/ShungiteHome'));
const ChangePassword = React.lazy(() => import('./pages/ChangePassword'));
const CatBanners = React.lazy(() => import('./pages/Admin/CatBanner'));
const CatBannerForm = React.lazy(() => import('./pages/Admin/CatBanner/Form'));
const UpdateRegistration = React.lazy(() => import('./pages/Admin/Registrations/UpdateRegistration'));
const ProductsByShapes = React.lazy(() => import('./pages/ProductsByShapes'));

const Catalog = React.lazy(() => import('./pages/Admin/Catalog'));
const MegaMenuList = React.lazy(() => import('./pages/Admin/MegaMenu'));
const MegaMenuDesigner = React.lazy(() => import('./pages/Admin/MegaMenu/MegaMenuDesigner'));
const InstagramPosts = React.lazy(() => import('./pages/Admin/Instagram/Index'));

const ProductTypesList  = React.lazy(()=>import('./pages/ProductTypes'));

const ConditionalRoute:React.FC<any> = (props:any)=>{
    const {state,dispatch} = useStoreContext();
    
    //return <Route path={props.path} component={ React.memo(props.component)} {...props}></Route>;

    if( props.role.indexOf(state.user.role) !== -1 )
        return <Route path={props.path} component={ React.memo(props.component)} {...props}></Route>;
    else if(props.role == null)
        return <Route path={props.path} component={React.memo(props.component)} {...props}></Route>;
    else 
        return <Redirect to="/"/>;
    
}

const Loading = ()=>{
    return (
        <>
            <IonCard>
                <IonCardContent>
                    loading....
                </IonCardContent>
            </IonCard>
        </>
    )
}


const Routes:React.FC = ()=>{ 
    return(  
        <Suspense fallback={<Loading/>}>
            <IonRouterOutlet style={{position:'inherit'}}>
            <Switch> 
                  
                    <Route path="/" component={Dashboard} exact />

                    <Route path="/products" exact component={Products} />
                    <Route path="/search" exact component={Products} />
                    
                    <Route path="/products/:class/:category/:type/:subType/:page?" exact component={Products} key="products11"  />
                    

                    <Route path="/products-A-Z/" exact component={ProductsAZ}  key="productsAZ"/>
                    <Route path="/products-by-shapes/" exact component={ProductsByShapes}  key="productsByShapes"/>
                    
                    <Route path="/access-denied" exact component={AccessDenied}  />

                    <Route path="/Search/:term/:sort/:page?" component={Search} key="general-search"/>
                    <Route path="/product/:id/:name" component={Product} />
                    
                    <Route path="/aboutus" component={AboutUs} exact/>
                    <Route path="/contactus" component={ContactUs} exact/>

                    <Route path="/relatedProducts/:type/:page?" component={RelatedProducts} exact key="related-products"/> 
                    
                    <ConditionalRoute path="/cart" component={Cart} exact role={['admin','customer']} /> 
                    <ConditionalRoute path="/checkout" component={CheckOut} exact role={['admin','customer']} /> 
                    
                    <ConditionalRoute path="/myAccount" component={MyAccount} exact role={['admin','customer']} /> 
                    <ConditionalRoute path="/myorders" component={MyOrders} exact role={['admin','customer']} /> 
                    <ConditionalRoute path="/editAccount" component={EditAccount} exact role={['admin','customer']} /> 
                    <ConditionalRoute path="/editAddressBook" component={EditAddressBook} exact role={['admin','customer']} /> 

                    <ConditionalRoute path="/quickOrder" component={QuickOrder} exact role={['admin','customer']} /> 
                    <ConditionalRoute path="/Registration" exact component={Registration} role={['guest']} />
                    <ConditionalRoute path="/login" component={Login} exact role={['guest']} />
                    <ConditionalRoute path="/resetPassword" component={ResetPassword} exact role={['guest']} />
                    
                    <ConditionalRoute path="/productByShapes" component={ProductTypesList} exact role={['guest','admin','customer']} /> 
                    

                    <ConditionalRoute path="/admin/" component={AdminDashboard} exact role={['admin']} />
                    <ConditionalRoute path="/admin/registration" component={Registrations} exact role={['admin']} />
                    <ConditionalRoute path="/admin/registration/doc/:id" component={UpdateRegistration} exact role={['admin']} />

                    <ConditionalRoute path="/admin/ticker" component={Ticker} role={['admin']} />
                    <ConditionalRoute path="/admin/banners" component={Banners} exact role={['admin']} />
                    <ConditionalRoute path="/admin/banners/doc/:id?" component={BannerForm} role={['admin']} />
                    <ConditionalRoute path="/admin/categories" component={Categories} exact role={['admin']} />
                    <ConditionalRoute path="/admin/categories/doc/:id?" component={CategoryForm} role={['admin']} />
                    <ConditionalRoute path="/admin/products" component={ProductsAdmin} exact role={['admin']} />
                    <ConditionalRoute path="/admin/products/doc/:id?" component={ProductForm} role={['admin']} />
                    <ConditionalRoute path="/admin/orders" component={Orders} exact role={['admin']} />
                    <ConditionalRoute path="/admin/webmenu" component={WebMenu} exact role={['admin']} />
                    <ConditionalRoute path="/admin/catmenu" component={CatMenu} exact role={['admin']} />
                    <ConditionalRoute path="/admin/adminusers" component={AdminUsers} exact role={['admin']} />
                    <ConditionalRoute path="/admin/adminusers/doc/:id?" component={AdminUserForm} role={['admin']} />
                    <ConditionalRoute path="/admin/productTypes" component={ProductTypes} exact role={['admin']} />
                    <ConditionalRoute path="/admin/productTypes/doc/:id?" component={ProductTypeForm} exact role={['admin']} />
                    <ConditionalRoute path="/admin/productSubTypes" component={ProductSubType} exact role={['admin']} />
                    <ConditionalRoute path="/admin/productSubTypes/doc/:id?" component={ProductSubTypeForm} exact role={['admin']} />
                    <ConditionalRoute path="/admin/tradeShows" component={TradeShows} exact role={['admin']} />
                    <ConditionalRoute path="/admin/tradeShows/doc/:id?" component={TradeShowsForm} exact role={['admin']} />
                    <ConditionalRoute path="/admin/productClass" component={ProductClass} exact role={['admin']} />
                    <ConditionalRoute path="/admin/productClass/doc/:id?" component={ProductClassForm} exact role={['admin']} />
                    <ConditionalRoute path="/admin/categories" component={Categories} exact role={['admin']} />
                    <ConditionalRoute path="/admin/categories/:action/:id?" component={CategoriesForm} role={['admin']} />
                    <ConditionalRoute path="/admin/dcontent/" component={DContent} role={['admin']} />
                    <ConditionalRoute path="/admin/salesPersons" component={SalesPersons} exact role={['admin']} />
                    <ConditionalRoute path="/admin/salesPerson/doc/:id?" component={SalesPersonForm} exact role={['admin']} />
                    <ConditionalRoute path="/admin/schedular" component={Schedular} exact role={['admin']}/>    
                    <ConditionalRoute path="/admin/cartTracker" component={CartTracker} exact role={['admin']}/>    
                    <ConditionalRoute path="/admin/theme" component={Theme} exact role={['admin']}/>    
                    <ConditionalRoute path="/admin/mega-menu" component={MegaMenuList} exact role={['admin']}/>    
                    <ConditionalRoute path="/admin/mega-menu/designer/:id?" component={MegaMenuDesigner} exact role={['admin']}/>    
                    <ConditionalRoute path="/admin/instagram" component={InstagramPosts} exact role={['admin']}/>    
                    

                    <ConditionalRoute path="/admin/cat_banners" component={CatBanners} exact role={['admin']}/>    
                    <ConditionalRoute path="/admin/cat_banners/doc/:id?" component={CatBannerForm} exact role={['admin']} />

                    <ConditionalRoute path="/admin/catalog" component={Catalog} exact role={['admin']} />

                    <Route path="/changePassword/:uuid?" component={ChangePassword}/>

                    <Route path="/shungite" component={ShungiteHome} exact/> 
                    
                    <Redirect to="/"/>
                    

              
        
                
                </Switch>
            </IonRouterOutlet>
        </Suspense>
       
    )
}

export default Routes;